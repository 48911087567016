<template>
  <div>
    <div class="my-4 d-flex justify-content-between">
      <div>
        <h4>{{ form.fantasy_name }}</h4>
        <div>
          <span>CNPJ: {{ form.cnpj }}</span>
        </div>
      </div>
      <div v-if="user.is_admin">
        <b-button @click="handleConfirmDelete" variant="danger" size="sm">
          Excluir
        </b-button>
      </div>
    </div>
    <div class="d-md-flex gap-2">
      <b-card class="min-h-70 col-md-8 p-0">
        <b-form
          :form="form"
          @submit.prevent="handleSubmit"
          class="d-flex flex-column justify-content-between h-100"
        >
          <div class="row">
            <div class="col-md-4">
              <b-form-group label="CNPJ" class="input-loading">
                <b-input
                  disabled
                  v-model="$v.form.cnpj.$model"
                  :class="invalid_cnpj && 'is-invalid'"
                  v-mask="'##.###.###/####-##'"
                  placeholder="CNPJ"
                />
                <b-icon icon="disc" v-if="loadingCnpj" />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Razão Social">
                <b-form-input
                  v-model="form.social_reason"
                  placeholder="Razão Social"
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Nome Fantasia">
                <b-form-input
                  v-model="form.fantasy_name"
                  placeholder="Nome Fantasia"
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Atividade Principal">
                <b-form-input
                  v-model="form.segment"
                  placeholder="Atividade Principal"
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="CEP" class="input-loading">
                <b-form-input
                  v-model="form.zip_code"
                  @keyup="getZipCode"
                  v-mask="'#####-###'"
                  placeholder="CEP"
                />
                <b-icon icon="disc" v-if="loading_zip_code" />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Cidade">
                <b-form-input v-model="form.city" placeholder="Cidade" />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="UF">
                <b-form-input v-model="form.state" placeholder="UF" />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Logradouro">
                <b-form-input v-model="form.place" placeholder="Logradouro" />
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-form-group label="Número">
                <b-form-input v-model="form.number" placeholder="Número" />
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-form-group label="Complemento">
                <b-form-input
                  v-model="form.complement"
                  placeholder="Complemento"
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Bairro">
                <b-form-input
                  v-model="form.neighborhood"
                  placeholder="Bairro"
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Contato">
                <b-form-input v-model="form.phone" placeholder="Contato" />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Email">
                <b-form-input
                  v-model="$v.form.email.$model"
                  :class="!$v.form.email.email && 'is-invalid'"
                  placeholder="empresa@email.com"
                />
                <b-form-invalid-feedback
                  v-if="!$v.form.email.email || !$v.form.email.required"
                >
                  {{ $t("auth.type-valid-email") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <div class="mt-3 d-flex w-100 justify-content-end">
            <b-button
              :disabled="isDisabled() || loadingSubmit"
              type="submit"
              variant="primary"
            >
              <b-spinner v-if="loadingSubmit" small class="mr-2" />
              <span>Salvar </span>
            </b-button>
          </div>
        </b-form>
      </b-card>
      <b-card class="min-h-70 col-md-4 p-0">
        <h6>Responsáveis legais</h6>
        <empty-list
          v-if="!form.responsibles.length"
          text="Não há responsáveis cadastrados"
        />
        <div class="py-2" v-if="form.responsibles">
          <b-card
            v-for="(prv, index) in form.responsibles"
            :key="index"
            class="mb-2 card-hoverable"
          >
            <router-link
              :to="`/users/detail/${prv.id}`"
              target="_blank"
              class="text-muted"
            >
              <div class="d-block" v-if="prv.is_main">
                <div class="d-flex gap-2 align-items-center mb-1">
                  <avatar name="Allison" width="60" height="60" />
                  <strong class="mr-1 d-block">
                    {{ prv.name }}
                  </strong>
                </div>
                <div>
                  <b-icon icon="bookmark-star-fill" variant="warning" />
                  <small class="text-muted"> Responsável principal </small>
                </div>
                <span> {{ prv.email }}</span>
              </div>
              <div class="d-block" v-else>
                <div class="d-flex gap-2 align-items-center mb-1">
                  <avatar name="Allison" width="60" height="60" />
                  <strong class="mr-1 d-block">
                    {{ prv.name }}
                  </strong>
                </div>
                <span> {{ prv.email }}</span>
              </div>
            </router-link>
          </b-card>
        </div>
        <router-link :to="`/legal-responsible/list/?enterprise_id=${form.id}`">
        </router-link>
      </b-card>
    </div>
    <b-modal id="delete-provider" centered size="md">
      <template #modal-header> <h5>Cuidado!</h5></template>
      <p>
        Você apagará o tomador
        <strong> {{ selected.social_reason }} </strong>. <br />
        Essa ação não poderá ser desfeita, deseja continuar?
      </p>
      <template #modal-footer="{ close }">
        <b-button variant="link" size="sm" @click="handleDeleteRow()">
          Sim
        </b-button>
        <b-button variant="primary" size="sm" @click="close()">Não</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { api } from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  directives: { mask },
  data() {
    return {
      id: null,
      selected: {},
      disableCheck: false,
      invalid_cnpj: false,
      valid_cnpj: false,
      form: {
        social_reason: null,
        fantasy_name: null,
        segment: null,
        zip_code: null,
        city: null,
        state: null,
        place: null,
        number: null,
        complement: null,
        neighborhood: null,
        phone: null,
        responsibles: [],
      },
      entrerpise_data: null,
      email: null,
      loadingSubmit: false,
      loadingCnpj: false,
      loading_zip_code: false,
    };
  },
  validations: {
    form: {
      cnpj: { required },
      social_reason: { required },
      segment: { required },
      zip_code: { required },
      city: { required },
      state: { required },
      place: { required },
      number: { required },
      neighborhood: { required },
      phone: { required },
      email: {
        required,
        email: email,
      },
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions([
      "show_toast",
      "get_provider",
      "edit_provider",
      "delete_provider",
    ]),
    isDisabled() {
      if (this.invalid_cnpj || !this.form.cnpj) {
        return true;
      }
      return false;
    },
    isDisabledInput(element) {
      return !(this.valid_cnpj && !element);
    },

    getZipCode() {
      let zipCode = this.form.zip_code;
      if (zipCode) {
        zipCode = zipCode.replace(/[^\d]/g, "");
        if (zipCode.length === 8) {
          this.loading_zip_code = true;
          api
            .get(`/homologation/get-zip-code/${zipCode}/`)
            .then(({ data }) => {
              if (data.erro) {
                this.invalid_cep = true;
              } else {
                this.invalid_cep = false;
              }
              this.responsible_data = data;
              this.form = {
                ...this.form,
                city: this.responsible_data.localidade,
                state: this.responsible_data.uf,
                place: this.responsible_data.logradouro,
                neighborhood: this.responsible_data.bairro,
              };
              this.loading_zip_code = false;
            })
            .catch(() => {
              this.loading_zip_code = false;
            });
        }
      }
    },

    async handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loadingSubmit = true;
        await this.edit_provider({
          ...this.getFormDataObject(),
          id: this.id,
        }).then(({ status }) => {
          this.loadingSubmit = false;
          if (status === 200) {
            this.$router.push("/provider/list");
          }
        });
      } else {
        this.show_toast({
          message:
            "Há algum problema com dados cadastrados, verifique e tente novamente!",
          type: "error",
        });
      }
    },

    getFormDataObject() {
      const objectForm = {
        legal_person: {
          id: this.user.id,
          social_reason: this.form.social_reason,
          fantasy_name: this.form.fantasy_name,
          segment: this.form.segment,
          email: this.form.email,
          cnpj: this.form.cnpj?.replace(/[^\d]/g, ""),
          address: {
            place: this.form.place,
            number: this.form.number,
            complement: this.form.complement,
            zip_code: this.form.zip_code?.replace(/[^\d]/g, ""),
            neighborhood: this.form.neighborhood,
            city: this.form.city,
            state: this.form.state,
            country: "Brasil",
          },
        },
      };
      return objectForm;
    },

    resetForm() {
      this.form = {
        cnpj: this.form.cnpj,
        social_reason: null,
        fantasy_name: null,
        segment: null,
        zip_code: null,
        city: null,
        state: null,
        place: null,
        number: null,
        complement: null,
        neighborhood: null,
        phone: null,
      };
    },

    getEdit() {
      this.get_provider({ id: this.id }).then(({ data }) => {
        this.form = data;
        this.form.zip_code = data.address.zip_code;
        this.form.city = data.address.city;
        this.form.state = data.address.state;
        this.form.place = data.address.place;
        this.form.number = data.address.number;
        this.form.complement = data.address.complement;
        this.form.neighborhood = data.address.neighborhood;

        this.$forceUpdate();
      });
    },

    handleConfirmDelete() {
      this.selected = this.form;
      this.$bvModal.show("delete-provider");
    },

    handleDeleteRow() {
      this.delete_provider(this.id).then(() => {
        this.$bvModal.hide("delete-provider");
        this.$router.push("/provider/list/");
      });
    },
  },
  beforeUpdate() {
    if (!this.form.responsibles?.length) {
      return (this.form.responsibles = []);
    }
    this.form.responsibles = this.form.responsibles.sort((a, b) =>
      a.is_main < b.is_main ? 1 : -1
    );
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getEdit();
  },
};
</script>
